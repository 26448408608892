

































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import { Profile } from 'client-website-ts-library/types';
import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';
import ProfileCard from '../ProfileCard.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
    ProfileCard,
  },
})
export default class AgentCardCycler extends Vue {
  @Prop()
  public agentProfiles: Profile[] | null = null;

  public autoAdvance = true;

  public hiddenCards = false;

  public initialSlide = 0;

  public breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 8,
      },
    },
  ];

  isEven(num: number) {
    return num % 2 === 0;
  }

  @Watch('agentProfiles')
  handleAgentsChanged() {
    if (this.agentProfiles !== null) {
      this.autoAdvance = this.agentProfiles.length > 4;
      this.hiddenCards = this.agentProfiles.length <= 4;
      this.initialSlide = this.hiddenCards
        ? Math.floor(this.agentProfiles.length / 2)
        : 0;
    }
  }
}
